import { SENTRY_DSN } from '@env';
import * as SentrySvelte from '@sentry/svelte';
import type { HandleClientError } from '@sveltejs/kit';

import { env } from '@api/utils';
import { IS_DEV } from '@api/utils/env';

const envNames = ['dev', 'test', 'alpha', 'beta', 'prod'];

if (SENTRY_DSN) {
    SentrySvelte.init({
        dsn: SENTRY_DSN,
        environment: envNames[env],
    });
}

export const handleError: HandleClientError = ({ error, event }) => {
    if (typeof error === 'object') {
        const msg = error['message'] as string;
        if (msg?.includes('Failed to fetch dynamically imported module')) {
            window.location.reload();
        }
    }

    let errorId = '';
    if (crypto && crypto.randomUUID) {
        errorId = crypto.randomUUID();
    }

    if (SENTRY_DSN) {
        SentrySvelte.captureException(error, {
            contexts: { sveltekit: { event, errorId } },
        });
    }

    if (IS_DEV) {
        console.error({ error });
        console.error({ type: typeof error });
    }

    return {
        message: 'Une erreur est survenue',
        errorId,
    };
};
