import * as client_hooks from '../../../../_commons/hooks-client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40')
];

export const server_loads = [0,3,8,5];

export const dictionary = {
		"/(main)/(open)": [~32,[2,9]],
		"/(main)/(open)/accompagnement": [~33,[2,9]],
		"/(main)/(open)/aides": [~34,[2,9]],
		"/(main)/(open)/aides/[category=category]/[...subCategory]": [~35,[2,9]],
		"/(main)/(open)/aides/[slug]": [~36,[2,9]],
		"/(main)/(open)/aides/[slug]/[old]": [~37,[2,9]],
		"/(main)/(open)/cgu": [~38,[2,9]],
		"/(main)/(open)/contact": [~39,[2,9]],
		"/(auth)/email/verify": [~10],
		"/(auth)/follow-up": [~11],
		"/(auth)/info": [~12],
		"/(auth)/password/forgot": [13],
		"/(auth)/password/reset": [14],
		"/(main)/(app)/settings/preferences": [31,[2,3,8],[,4]],
		"/(auth)/signin": [~15],
		"/(auth)/signup": [~16],
		"/(auth)/simulation": [~17],
		"/(main)/(open)/tarifs": [~40,[2,9]],
		"/(main)/(app)/[companyId=guid]": [~18,[2,3,5],[,4,6]],
		"/(main)/(app)/[companyId=guid]/aides": [~19,[2,3,5],[,4,6]],
		"/(main)/(app)/[companyId=guid]/aides/[slug]": [~20,[2,3,5],[,4,6]],
		"/(main)/(app)/[companyId=guid]/audit/[requestId]": [~21,[2,3,5],[,4,6]],
		"/(main)/(app)/[companyId=guid]/rapport-eligibilite/[id]": [~22,[2,3,5],[,4,6]],
		"/(main)/(app)/[companyId=guid]/redirect": [23,[2,3,5],[,4,6]],
		"/(main)/(app)/[companyId=guid]/settings": [~24,[2,3,5,7],[,4,6]],
		"/(main)/(app)/[companyId=guid]/settings/account": [25,[2,3,5,7],[,4,6]],
		"/(main)/(app)/[companyId=guid]/settings/billing": [26,[2,3,5,7],[,4,6]],
		"/(main)/(app)/[companyId=guid]/settings/company": [27,[2,3,5,7],[,4,6]],
		"/(main)/(app)/[companyId=guid]/settings/security": [28,[2,3,5,7],[,4,6]],
		"/(main)/(app)/[companyId=guid]/settings/team": [~29,[2,3,5,7],[,4,6]],
		"/(main)/(app)/[companyId=guid]/subscribe": [30,[2,3,5],[,4,6]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';